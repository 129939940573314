


























import { Vue, Component } from 'vue-property-decorator'
import AppStore from '@/store/vuex/aplicacao/AppStore'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component
export default class BotaoDeDrawer extends Vue  {
	UserLoginStore = UserLoginStore

	get temSomenteUmMenuPermitido() {
		return AppStore.temSomenteUmMenuPermitido
	}

	get mostraNavBar() {
		return AppStore.showSideBar
	}

	set mostraNavBar(valor: typeof AppStore.showSideBar) {
		AppStore.setShowSideBar(valor)
	}

	get mostrarContagem() {
		return UserLoginStore.mostrarContagem
	}
}

