



























































































































import { Download } from '@/models'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { DownloadModule, possuiDownloadsPendentes } from '@/store/vuex/fiscal/DownloadStore'
import { DownloadZipUseCase, CreateDownloadUseCase, LimparDownloadsUseCase } from '@/usecases'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import Confirmacao from './Confirmacao.vue'

@Component({
	components: {
		Confirmacao,
	},
})
export default class DialogoDeDownload extends Vue {

	@Ref("confirmacaoExcluir")
	confirmaExclusao!: Confirmacao

	@Ref("confirmacaoCarregar")
	confirmaCarregar!: Confirmacao

	@Ref("confirmacaoLimpar")
	confirmaLimpar!: Confirmacao

	gerandoZip = false
	downloadExcluir: Download | null = null
	menuVisivel = false

	gerarDownloadUseCase = new CreateDownloadUseCase()
	downloadZipUseCase = new DownloadZipUseCase()
	limparDownloadsUseCase = new LimparDownloadsUseCase()

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	removeDownloadConfirma(idDownload: string) {
		
		this.downloadExcluir = DownloadModule.downloadsNF.find(({id}) => id === idDownload) || null
		this.$nextTick(() => this.confirmaExclusao.mostrar())
	}

	removeDownload() {
		if (!this.downloadExcluir) return
		DownloadModule.removeDownloadNF(this.downloadExcluir.id)
	}

	carregarDownloads() {
		DownloadModule.loadDownloadsIDBPendentes()
	}

	limparDadosDownload() {
		this.limparDownloadsUseCase.limparDadosDownload()
	}

	async baixar(id: string) {
		try {
			this.gerandoZip = true
			await this.downloadZipUseCase.gerarZip(id)
		} catch(error) {
			AlertModule.setError(error)
		}

		this.gerandoZip = false
	}

	continuarDownload(idDownload: string) {
		this.downloadZipUseCase.baixarXmls(idDownload)
	}
	pausarDownload(idDownload: string) {
		this.downloadZipUseCase.pausarDownloads(idDownload)
	}
	
	get downloadsOrdenados() {
		return DownloadModule.downloadsNF.sort((d1, d2) => {
			if (d1.createdAt > d2.createdAt) return -1
			if (d1.createdAt < d2.createdAt) return 1
			return 0
		}).map(d => ({
			...d,
			percentual: ((d.itens.map(i => i.pronto).reduce((valor, item) => item ? ++valor : valor, 0) / d.itens.length ) * 100).toFixed(2),
		}))
	}

	get notificarDownload() {
		return DownloadModule.notificarDownload && !this.menuVisivel
	}

	set notificarDownload(value) {
		DownloadModule.setNotificar(value)
	}

	async mounted() {
		DownloadModule.loadDownloadsIDBConcluidos()

		const pendentes = await possuiDownloadsPendentes()
		if (!pendentes) return

		this.$nextTick(this.confirmaCarregar.mostrar)
	}

	created() {
		window.onbeforeunload = function () {
			DownloadModule.salvarDownloadsIDB()
		};
	}

	@Watch("menuVisivel")
	onMenuVisivel(valor) {
		if (!valor) {
			DownloadModule.setNotificar(false)
		}
	}
}
