














import { Notificacao } from '@/models'
import NotificacaoStore from '@/store/vuex/aplicacao/NotificacaoStore'
import { UpdateNotificacaoUseCase } from '@/usecases'
import { Vue, Component } from 'vue-property-decorator'

@Component({
	components: {
		AvisoDeNotificacao,
	},
})
export default class AvisoDeNotificacao extends Vue {

	notificacaoVisivel = true;

	fecharNotificacao() {
		this.notificacaoVisivel = false;
	}

	notificacoesContent: Notificacao[] = []
	atualizarNoficacoesUsecase = new UpdateNotificacaoUseCase()
	
	set notificacoes(notificacoes: Notificacao[]) {
		NotificacaoStore.setNotificacoes(notificacoes)
	}

	get notificacoes() {
		return NotificacaoStore.notificacoes
	}

	get quantiaDeNotificacoesNaoLidas (){
		return this.notificoesNaoLidas.length
	}

	get notificoesNaoLidas(){
		return this.notificacoes.filter(notificacao => notificacao.lido === false) 
	}

}
