










































































import { Notificacao } from '@/models'
import { formatarDataHora } from '@/shareds/formatadores'
import NotificacaoStore from '@/store/vuex/aplicacao/NotificacaoStore'
import { DispensarNotificacao, UpdateNotificacaoUseCase } from '@/usecases'
import moment from 'moment'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class MenuDeNotificacao extends Vue {
	dispensarNotificacao = new DispensarNotificacao()
	updateNotificaocaoUseCase = new UpdateNotificacaoUseCase()

	formatarDataHora = formatarDataHora

	get notificacoes() {
		return NotificacaoStore.notificacoes
	}
	
	set notificacoes(notificaco: Notificacao[]) {
		NotificacaoStore.setNotificacoes(notificaco)
	}

	get notificacoesOrdenadas() {
		return [...this.notificacoes].sort((notificacaoA, notificacaoB) => moment(notificacaoB.dataEHora).diff(notificacaoA.dataEHora))
	}

	async limparNotificacao(notificacao: Notificacao) {
		const indice = this.notificacoes.findIndex(notificacaoExistente => notificacaoExistente.id === notificacao.id)
		if(indice === -1) return

		const notificacoes = [...this.notificacoes]
		notificacoes.splice(indice, 1)
		this.notificacoes = notificacoes

		await this.dispensarNotificacao.executar(notificacao)
	}

	async abrirNotificacao(notificacao: Notificacao) {
		this.notificacoes.forEach((notif) => {
			if (notif.id === notificacao.id) {
				notif.visto = true;
				this.updateNotificaocaoUseCase.update(notif)
			}
		})
		
		switch (notificacao.titulo) {
			case 'Venda integrada concluída':
				this.$router.push('/pedidos')
				break
			case 'Pagamento via link':
				this.$router.push('/vendas')
				break
			case 'RECEBIDO' :
				this.$router.push('/listagem-de-notas')
				break
			default:
				if (notificacao.link) this.$router.push(notificacao.link)
				break

		}
	}

	formatarDescricao(descricao) {
		return descricao.replace(/\n/g, "<br>");
	}
}
