var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.temPermissaoItemDeMenu(	{
				icon:'mdi-progress-upload',
				title:'Importações',
				route: { name: 'Importacoes' },
				recurso: 'importacoes',
			}))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.irPara()}}},on),[_vm._v(" mdi-progress-upload ")])]}}],null,false,709357938)},[_vm._v(" Processos ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }