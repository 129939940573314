






















































import { Component, Vue } from 'vue-property-decorator'
import AppStore from '@/store/vuex/aplicacao/AppStore'

@Component({})
export default class Help extends Vue{
	icone = true
	version = process.env.PACKAGE_VERSION || ''

	mostraSumario() {
		AppStore.setMostraSumarioDeTeclas(true)
	}
}
