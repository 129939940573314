




























import { Notificacao } from '@/models'
import NotificacaoStore from '@/store/vuex/aplicacao/NotificacaoStore'
import { UpdateNotificacaoUseCase } from '@/usecases'
import { Vue, Component } from 'vue-property-decorator'
import MenuDeNotificacao from './MenuDeNotificacao.vue'

@Component({
	components: {
		MenuDeNotificacao,
	},
})
export default class BotaoDeNotificacao extends Vue {

	notificacoesContent: Notificacao[] = []
	atualizarNoficacoesUsecase = new UpdateNotificacaoUseCase()

	set notificacoes(notificacoes: Notificacao[]) {
		NotificacaoStore.setNotificacoes(notificacoes)
	}

	get notificacoes() {
		return NotificacaoStore.notificacoes
	}

	get quantiaDeNotificacoesNaoLidas (){
		return this.notificoesNaoLidas.length
	}

	get notificoesNaoLidas(){
		return this.notificacoes.filter(notificacao => notificacao.lido === false) 
	}

	async atualizar() {
		if(!this.quantiaDeNotificacoesNaoLidas) return
	
		const notificacoes = this.notificoesNaoLidas
			.map(notificacao  => ({
				...notificacao,
				lido: true,
			}))
		this.notificacoes = notificacoes
		await this.atualizarNoficacoesUsecase.updateAll(notificacoes)
	}
}
