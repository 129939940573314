var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.notificarDownload = false}}},Object.assign({}, on, tooltip)),[_c('v-badge',{attrs:{"value":_vm.notificarDownload,"color":"blue","dot":""}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('Confirmacao',{ref:"confirmacaoCarregar",attrs:{"width":"300","opcaoPadrao":"Sim","titulo":"Confirma?","subtitulo":"Existem downloads pendentes deseja carrega-los?"},on:{"confirmar":_vm.carregarDownloads}})],1)]}}],null,true)},[_c('span',[_vm._v("Downloads")])])]}}]),model:{value:(_vm.menuVisivel),callback:function ($$v) {_vm.menuVisivel=$$v},expression:"menuVisivel"}},[_c('v-card',{attrs:{"max-height":"80vh"}},[_c('v-card-title',{staticClass:"d-flex"},[_c('h4',[_vm._v("Downloads")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-auto",attrs:{"icon":""},on:{"click":function($event){return _vm.confirmaLimpar.mostrar()}}},tooltip),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}])},[_c('span',[_vm._v("Limpar dados de download")]),_c('Confirmacao',{ref:"confirmacaoLimpar",attrs:{"width":"300","opcaoPadrao":"Sim","titulo":"Atenção!","subtitulo":"Deseja remover todos os dados de download?"},on:{"confirmar":_vm.limparDadosDownload}})],1)],1),_c('v-card-text',[_c('v-list',[(!_vm.downloadsOrdenados.length)?_c('v-list-item',[_vm._v(" Não existem downloads pendentes ")]):_vm._l((_vm.downloadsOrdenados),function(download){return _c('v-list-item',{key:download.id},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(download.identificador)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(download.itens.some(function (i) { return i.erro; }))?_c('v-icon',_vm._g({staticClass:"ml-auto",attrs:{"small":""}},on),[_vm._v("mdi-alert")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Alguns itens não foram baixados")])])],1),_c('v-list-item-subtitle',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.dateTimeToPtBrFormat(download.createdAt))+" - "+_vm._s(download.status)+" "),_c('span',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(download.percentual)+"% ")])]),_c('v-progress-linear',{attrs:{"value":download.percentual}})],1),_c('v-list-item-action',{staticClass:"ml-5"},[(download.status == 'Concluido')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.gerandoZip},on:{"click":function($event){return _vm.baixar(download.id)}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Baixar")])]):_vm._e(),(download.status == 'Em Andamento')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.gerandoZip},on:{"click":function($event){return _vm.pausarDownload(download.id)}}},on),[_c('v-icon',[_vm._v("mdi-pause")])],1)]}}],null,true)},[_c('span',[_vm._v("Pausar")])]):_vm._e(),(download.status == 'Pausado')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.gerandoZip},on:{"click":function($event){return _vm.continuarDownload(download.id)}}},on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}],null,true)},[_c('span',[_vm._v("Continuar")])]):_vm._e()],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.removeDownloadConfirma(download.id)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1)],1)})],2)],1),(_vm.downloadExcluir)?_c('Confirmacao',{ref:"confirmacaoExcluir",attrs:{"width":"300","opcaoPadrao":"Sim","titulo":"Remover?","subtitulo":("O download <u>" + (_vm.downloadExcluir.identificador) + "</u> será perdido")},on:{"confirmar":_vm.removeDownload}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }