





















import { Vue,Component, Prop } from 'vue-property-decorator'
import router from '@/router'
import { ItemDeMenu } from '@/shareds/itensDeMenu'
import AppStore from '@/store/vuex/aplicacao/AppStore'

@Component
export default class BotaoDaTelaDeProcessos extends Vue {
	

		@Prop({ type: Array, default: () => [] }) itens!: ItemDeMenu[]

		temPermissaoItemDeMenu = AppStore.itemDeMenuEstaPermitido

		irPara() {
			router.push({
				path: `/importacoes`,
			})

		}
}
