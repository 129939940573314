




































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { Usuario } from '@/models'
import AppStore from '@/store/vuex/aplicacao/AppStore'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import Confirmacao from '@/components/ui/Confirmacao.vue'

@Component({
	components: {
		Confirmacao,
	},
})
export default class DialogoDeConfiguracaoDePerfil extends Vue{

	@Ref() confirmacaoDeDescartarVenda!: Confirmacao
	usuario: Usuario | null = null
	icone = true

	get modoNoturno() {
		return AppStore.modoNoturno
	}

	set modoNoturno(modoNoturno: boolean) {
		AppStore.setModoNoturno(modoNoturno)
	}

	tema(){
		this.modoNoturno = !this.modoNoturno
		this.icone = !this.icone
	}

	created(){
		this.usuario = UserLoginStore.usuario
	}

	async logout() {
		UserLoginStore.logout()
	}

	deslogarComVendaEmAndamento() {
		VendaModule.temVendaEmAndamento ? this.confirmacaoDeDescartarVenda.mostrar() : UserLoginStore.logout()
	}
}
